/*
	*
	*	CONFIGURATION : AFIT, FILLA RACING AGENCY
	*	AUTHOR        : MATEJ KAMENEC
	*	DATE          : 11/2014
	*	
*/

/* CLICK ON LOGIN */
$(document).on('click', '#js-loginTrigger', function(event) {
  event.preventDefault();
  event.stopPropagation(); 

  	  if(window.innerWidth > 1030) {
		if(!$(this).hasClass('--isOpen')) {
			$(this).addClass('--isOpen');
	  		$('#js-loginTarget').show('fade', 500);	
	  	}else {
	  		$(this).removeClass('--isOpen');
	  		$('#js-loginTarget').hide('fade', 500);	
	  	}
	  }else {
	  	$('#js--menuOpen').trigger('click');
	  	$('#js-loginTarget').show('fade', 500);	
	  }	
});

$(document).on('click', '#js-loginTarget .loginbox__inner', function(event) {
  event.stopPropagation();
});

$(document).on('click', 'body', function(event) {
  $('#js-loginTarget').hide('fade', 500);
});

$(document).on('click touchstart', '#js--menuOpen', function(event) {
	event.preventDefault();

	if(window.innerWidth <= 1030) {
		if(!$(this).hasClass('--isOpen')) {
			
			$('body').addClass('preAnimate');
			$(this).addClass('--isOpen');

			$('.responsiveoverlay').show('fade', 350);
			
			$('body, .navigation').animate({
				left: '65%'
			},{
				duration: 350  
			});

			$('.responsivenavigation').animate({left: '0'},{duration: 350});

			$('.logo').animate({
				left: '125%'
			},{
				duration: 350  
			});

		}else {
			$(this).removeClass('--isOpen');
			$('.responsiveoverlay').hide('fade', 100);
			$('body, .navigation, .logo').animate({
			    left: '0'
			}, 350, function() {
			    
				$('body').removeClass('preAnimate');
			});		
			$('.responsivenavigation').animate({left: '-65%'},{duration: 350});
			
		}
	}
});

$(document).on('click touchstart', '.responsiveoverlay', function(event) {
	event.preventDefault();
	$('#js--menuOpen').trigger('click');
});

$(document).ready(function(){
	createMenu();

	var isIE = document.all && document.querySelector && !document.addEventListener;
	if(isIE){
		// CLEARING FLOATED ELEMENTS
		$('.account-full').append('<div class="cfx"></div>');
		$('.account-main').append('<div class="cfx"></div>');
	}

	if($('.registration').length) {
		$('.registration__item').each(function(){
			var input = $(this).find('input[type="text"]');
			var placeholder = input.attr('placeholder');

			input.removeAttr('placeholder');

			if(input.hasClass('required')) {
				$(this).append('<p class="registration__item__fake">'+placeholder+' <span style="color: #03c611">*</span></p>')	
			}else {
				$(this).append('<p class="registration__item__fake">'+placeholder+'</p>')
			} 
		});	
	}
	
}); 


$(window).resize(function(){createMenu();}); 
 
function createMenu() {
	if(window.innerWidth <= 1030) {
		if(!$('.responsivenavigation').length) {
			$('body').prepend('<div class="responsiveoverlay"></div>');
			$('body').prepend('<div class="responsivenavigation"></div>');
			$('.navigation nav ul').clone().appendTo('.responsivenavigation');
		}		
		$('.responsivenavigation').css('height', window.innerHeight);
	}
}


/* SCROLL ON CLICK MENU ITEM, ONLY IF IT IS POSSIBLE */
$(document).on('click', '.navigation__list li a', function(event){
	var href = $(this).attr('href');
	if(href.indexOf("#") > -1) {
		event.preventDefault();

		if($('body').hasClass('preAnimate')) {
			$('#js--menuOpen').trigger('click');
		}

		$('html, body').animate({
	        scrollTop: $(href).offset().top - 74
	    }, 800);
	}
});

$(document).on('keyup keydown keypress', '.registration__item input[type="text"]', function(event){
	value =  $(this).val().length;
	if(value !== 0) {
		$(this).parent('.registration__item').find('.registration__item__fake').hide();	
	}else {
		$(this).parent('.registration__item').find('.registration__item__fake').show();
	}
	
})