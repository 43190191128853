/*
	*
	*	INIT.js
	* 
*/

function bodyScroll(el, negative) {
	if(typeof negative === 'undefined'){negative = 0}
	var range = el.offset().top;
	range = (range + negative) * 1;

	$('html, body').animate({
        scrollTop: range
    }, 200);
}

function accents(strAccents) {
	var strAccents = strAccents.split('');
	var strAccentsOut = new Array();
	var strAccentsLen = strAccents.length;
	var accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽžĚěŠšČčŘřŽžÝýÁáÍíÉéŤť -_';
	var accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZzEeSsCcRrZzYyAaIiEeTt---";
	for (var y = 0; y < strAccentsLen; y++) {
		if (accents.indexOf(strAccents[y]) != -1) {
			strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
		} else {
			strAccentsOut[y] = strAccents[y];
		}
	}
	strAccentsOut = strAccentsOut.join('');
	return strAccentsOut;
}